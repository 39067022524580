<template>
  <div class="showpdfContainer">
    <div style="position: relative" id="show">
      <div id="firstSlide" v-if="currentIndex === 0">
        <div>
          <div style="margin-bottom: 10px; text-align: center; font-size: 30px">
            {{ sessionName }}
          </div>
          <vue-qr :text="watchUrl" :size="200"></vue-qr>
        </div>
      </div>
      <canvas id="pdf-render" :key="canvasKey"></canvas>
    </div>
  </div>
</template>
  
<script>
import { getSessionPPT, getPPTNotes } from "../../api/api";
import VueQr from "vue-qr";
const PDFJS = require("pdfjs-dist");
PDFJS.GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.5.207/pdf.worker.js";

export default {
  components: {
    VueQr,
  },
  data() {
    return {
      currentIndex: null,
      id: this.$route.query.id,
      session_id: this.$route.query.session_id,
      sessionName: "",
      color: "#000000",
      url: "",
      client: null,
      watchUrl: "",
      loading: null,
      presentUrl: "",
      codeVisible: false,
      pdfDoc: null,
      total: 0,
      canvasKey: 0,
      isFull: false,
      status: false,
      presenterStatus: false,
    };
  },
  mounted() {
    this.presentUrl = `http://canhaohui-fwh.reachable-edu.com/control-pdf?id=${this.id}&session_id=${this.session_id}`;
    this.watchUrl = `http://canhaohui-fwh.reachable-edu.com/watch-pdf?id=${this.id}&session_id=${this.session_id}`;
    this.fetchData();

    document.onkeydown = (e) => {
      if (e.code === "ArrowLeft") {
        this.last();
      } else if (e.code === "ArrowRight") {
        this.next();
      }
    };
  },
  beforeDestroy() {
    if (this.client) {
      this.client.close();
    }
  },
  methods: {
    getPPTNotes() {
      getPPTNotes({ ppt_url: this.url }).then((res) => {
        console.log(res);
      });
    },
    initSocket() {
      this.client = new WebSocket("wss://team-chat.learningfirst.cn:9009");
      this.client.onopen = () => {
        console.log("WebSocket连接成功");
        this.status = true;
        this.client.send(
          JSON.stringify({
            type: "login",
            pptId: this.id,
          })
        );
      };
      this.client.onmessage = (e) => {
        console.log(e.data);
        let obj = JSON.parse(e.data);
        if (obj.msg === "主持人状态") {
          this.presenterStatus = obj.data;
        } else if (obj.msg==='登录成功' || obj.msg === '切换页面') {
          this.currentIndex = obj.data;
          this.getPdfPage(this.currentIndex < 1 ? 1 : this.currentIndex);
        }
      };
      this.client.onclose = () => {
        console.log("WebSocket连接关闭");
        this.status = false;
        this.client = null;
      };
    },
    fetchData() {
      this.loading = this.$loading({
        lock: true,
        text: "加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      getSessionPPT({
        id: this.id,
      }).then((res) => {
        if (res.data.code === 0) {
          if (res.data.data.type === "PPT") {
            this.url = res.data.data.pdf_url;
          } else {
            this.url = res.data.data.ppt_url;
          }

          this.sessionName = res.data.data.session.name;
          PDFJS.getDocument(this.url).promise.then((doc) => {
            this.pdfDoc = doc;
            this.total = doc.numPages;
            this.initSocket();
            this.loading.close();
          });
        }
      });
    },
    getPdfPage(number) {
      this.canvasKey++;
      this.pdfDoc.getPage(number).then((page) => {
        const viewport = page.getViewport();
        const canvas = document.getElementById("pdf-render");
        const context = canvas.getContext("2d");
        canvas.width = viewport.viewBox[2];
        canvas.height = viewport.viewBox[3];
        viewport.width = viewport.viewBox[2];
        viewport.height = viewport.viewBox[3];
        let show = document.getElementById("show");
        canvas.style.width = show.offsetWidth + "px";
        canvas.style.height = show.offsetHeight + "px";

        let renderContext = {
          canvasContext: context,
          viewport: viewport,
          // 这里transform的六个参数，使用的是transform中的Matrix(矩阵)
          transform: [1, 0, 0, -1, 0, viewport.height],
        };
        // 进行渲染
        page.render(renderContext);
      });
    },
    next() {
      if (this.currentIndex < this.total) {
        this.client.send(
          JSON.stringify({
            type: "changeIndex",
            pptId: this.id,
            index: this.currentIndex + 1,
          })
        );
      }
    },
    last() {
      if (this.currentIndex > 0) {
        this.client.send(
          JSON.stringify({
            type: "changeIndex",
            pptId: this.id,
            index: this.currentIndex - 1,
          })
        );
      }
    },
  },
};
</script>
  
  <style lang="scss">
.showpdfContainer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  #firstSlide {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    background: #fff;
  }
  #show {
    width: 100%;
    height: 100%;
    position: relative;
    .fullBtn {
      position: absolute;
      bottom: 10px;
      right: 10px;
    }
  }
}
</style>